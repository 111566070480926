<!--
import SeatModel from "@/components/seatModel/index";
<seat-model v-model="searchForm.saddle"></seat-model>
-->
<style lang="scss">
</style>
<template>

    <div>
        <el-select :value="value" @change="selectCar" :multiple="multiple" :disabled="disbaled"
                   :placeholder="placeholder"
                   style="width: 100%">
            <el-option label="全部车型" :value="0" v-if="isAll"></el-option>
            <el-option
                    v-for="car in list"
                    :key="car.value"
                    :label="car.name"
                    :value="car.value"
            >
            </el-option>
        </el-select>
    </div>

</template>

<script type="text/ecmascript-6">
    import {seatModelList} from "@/data/index"

    export default {
        //定义模版数据
        data() {
            return {
                seatModelList
            }
        },
        props: {
            value: {
                type: [Number, Array],
                default: 1
            },
            disbaled: {
                type: Boolean,
                default: false
            },
            isAll: {
                type: Boolean,
                default: false
            },
            placeholder: {
                type: String,
                default: "请选择"
            },
            priceWay: {
                type: String,
                default: "0"
            },
            multiple: {
                type: Boolean,
                default: false
            }
        },
        //计算属性
        computed: {
            list() {
                let list = JSON.parse(JSON.stringify(seatModelList));
                list.forEach((item, index) => {
                    if (this.priceWay === "0" && item.value == 14) {
                        return list.splice(index, 1);
                    }
                })
                return list;
            }
        },
        components: {},
        //定义事件方法
        methods: {
            selectCar(val) {
                this.$emit('input', val);
                this.$emit('selectSeat', val)
            }
        },
        //监听模版变量
        watch: {}

    }
</script>
