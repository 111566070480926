<style lang="scss" scoped>
    .dispatch-grab /deep/ {
        height: 100vh;
        position: relative;
        .el-form-item {
            margin-bottom: 0;
            border-bottom: 1px solid #f8f8f8;
            &:last-child, &:nth-last-child(2) {
                border-bottom: 0;
            }
        }
        .el-input__inner {
            height: 50px;
            line-height: 50px;
            font-size: 17px;
            border: 0;
        }
        .header {
            font-size: 12px;
            padding: 15px 12px;
            color: #666;
        }
        .driver-public {
            background: #FFFFFF;
            position: relative;
            p {
                font-size: 16px;
                line-height: 50px;
            }
            .el-form-item__content {
                display: flex;
                flex-direction: row;
                padding: 0 15px;
                .el-input__inner {
                    padding: 0;
                }

            }
        }
        .driver-price {
            p {
                min-width: 110px;
            }
            .price_input {
                flex: 1;
                font-size: 17px;
            }
        }
        .mod-confirm-footer {
            display: flex;
            position: absolute;
            width: 100%;
            bottom: 0;
            left: 0;
            .mint-button {
                flex: 1;
                border: 0;
                font-size: 17px;
                border-radius: 0;
                background: #fff;
                color: #fff;
                height: 44px;
                line-height: 44px;
                padding: 0;
                background: linear-gradient(90deg, rgba(80, 167, 242, 1) 0%, rgba(76, 219, 245, 1) 100%);

            }
        }
    }

</style>
<template>
    <div class="dispatch-grab">
        <p class="header">当前实收金额：
            <span style="color: #FF3B30">{{(refundAmount ? (paymentAmount - refundAmount) : paymentAmount) || 0}}</span>
            ，当前实付金额：<span style="color: #ff3b30">{{actualSettleAmount}}</span>
        </p>
        <el-form :model="form" ref="form">
            <el-form-item class="driver-public driver-price">
                <p>用户退款金额：¥</p>
                <input class="price_input" placeholder="退款金额不得超过实收金额" type="number"
                       v-model="form.refundAmount">
            </el-form-item>
            <el-form-item class="driver-public driver-price">
                <p>供应商结算金额：¥</p>
                <input class="price_input" placeholder="新实付金额" type="number"
                       v-model="form.actualSettleAmount">
            </el-form-item>
            <el-form-item class="driver-public driver-price">
                <p>平台赔付金额：￥</p>
                <input class="price_input" placeholder="请输入价格" type="number"
                       v-model="form.indemnityAmount">
            </el-form-item>
            <el-form-item class="driver-public driver-price" style="margin-top: 10px">
                <p style="min-width: 80px">退款原因：</p>
                <textarea style="padding-top: 13px" class="price_input" type="textarea" placeholder="请输入退款理由，核损明细等信息"
                          v-model="form.refundRemark" rows="5"></textarea>
            </el-form-item>
        </el-form>
        <div class="mod-confirm-footer">
            <mt-button @click="confirmBtn">确 定</mt-button>
        </div>
    </div>
</template>

<script type="text/ecmascript-6">
    import {Toast, Button} from 'mint-ui';
    import orderServer from '@/urls/orderUrls'


    export default {
        //定义模版数据
        data() {

            return {
                form: {
                    indemnityAmount: 0
                },
                confirmShow: false,
                orderId: this.$route.params.orderId,
                refundAmount: this.$route.params.refundAmount||0,
                paymentAmount: this.$route.params.paymentAmount,
                actualSettleAmount: this.$route.params.actualSettleAmount
            }
        },
        components: {
            MtButton: Button
        },
        //计算属性
        computed: {},
        activated() {

        },
        //主件被加载完成
        mounted: function () {


        },
        //定义事件方法
        methods: {
            async confirmBtn() {
                let price = (this.refundAmount?(this.paymentAmount-this.refundAmount):this.paymentAmount) || 0;
                if (!this.form.refundAmount&&this.form.refundAmount!=0)
                    return Toast('请填写金额');
                if (price-this.form.refundAmount<0)
                return Toast('退款金额不得超过实收金额');
                if (!this.form.actualSettleAmount&&this.form.actualSettleAmount!=0)
                    return Toast('请填写结算金额');
                if (!this.form.indemnityAmount&&this.form.indemnityAmount!=0)
                    return Toast('请填写赔付金额');
                if (!this.form.refundRemark)
                    return Toast('请填写退款原因');
                let form = {
                    actualSettleAmount: this.form.actualSettleAmount,
                    indemnityAmount: this.form.indemnityAmount,
                    orderId: this.orderId,
                    refundAmount: this.form.refundAmount,
                    refundRemark: this.form.refundRemark
                }
                let  res = await orderServer.cancelOrder(form);
                if (res.success && res.data) {
                    this.$store.dispatch('changeOrderStatus', {
                        orderId: this.orderId,
                        orderStatus: 71
                    })
                    Toast('订单取消成功');
                    this.$router.go(-1);
//                            this.getOrderList(this.$refs['searchForm'].searchForm, true);
                } else {
                    Toast(res.errors[0].message)
                }
            },
        },
        //监听模版变量
        watch: {}

    }
</script>
