<style lang="scss" scoped>
    .box-value_picker_time {
        display: flex;
        align-items: center;
        position: relative;
        cursor: pointer;
        overflow: hidden;
        border-radius: 4px;
        border: 1px solid #D8DCE6;
        &:hover {
            .box-value_icon {
                &.el-icon-circle-close {
                    display: block;
                }
            }
        }

        .box-value_before {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            opacity: 0;
            transition: all 0.2s ease-in-out;
            z-index: 14;
            &.box-value_before_input {
                opacity: 1;
                transition: all 0.2s ease-in-out;
            }

        }

        .box-value_after {
            padding: 2px 12px 0 12px;
            &.box-value_after_empty {
                padding: 0 12px;
                span {
                    transition: all 0.2s ease-in-out;
                    font-size: 14px;
                    line-height: 40px;
                }
            }
            span {
                transition: all 0.2s ease-in-out;
                font-size: 11px;
                color: #999999;
                line-height: 14px;
                display: block;
            }
            p {
                font-size: 14px;
                color: #333333;
                line-height: 19px;
            }
        }

        .box-value_icon {
            position: absolute;
            right: 8px;
            top: 2px;
            bottom: 2px;
            color: #c0c4cc;
            &.el-icon-caret-bottom, &.el-icon-arrow-down {
                z-index: 12;
                &.value_icon_caret_focus {
                    -webkit-transition: -webkit-transform .3s;
                    transition: -webkit-transform .3s;
                    transition: transform .3s, -webkit-transform .3s;
                    -webkit-transform: rotateZ(-180deg);
                    transform: rotateZ(-180deg);
                }
            }
            &.el-icon-circle-close {
                background: #fff;
                z-index: 18;
                display: none;
                &.box-value_icon_close {
                    display: none;
                }
            }
        }

    }

    .date-picker-take /deep/ {
        input {
            cursor: pointer;
        }

    }

    .box-hour-minute {
        li {
            text-align: center;
            line-height: 30px;
            cursor: pointer;
        }
    }

    .box-hour-minute /deep/ {
        .el-scrollbar__wrap {
            overflow-x: hidden;
        }
    }
    .box-li-select{
        background: #319BF7;
        color: #fff;
    }

</style>
<style lang="scss">
    .hour-minute-popover {
        &.el-popover {
            padding: 0;
        }
        .hour-minute-popover_title {
            text-align: center;
            line-height: 30px;
            border-bottom: 1px solid #EEEEEE;
        }
        .hour-minute-popover_oper{
            border-top: 1px solid #EEEEEE;
            text-align: center;
            line-height: 30px;
            .el-button{
                padding: 8px 0;
                border: 0;
                width: 100%;
            }
            .oper-close{
                &.el-button{
                    background: transparent;
                    color: #606266;
                    &:hover{
                        color: #606266;
                    }
                }
            }
            /*.oper-sub{*/
                /*&.el-button{*/
                    /*background: transparent;*/
                    /*color: #319BF7;*/
                    /*&:hover{*/
                        /*color: #319BF7;*/

                    /*}*/
                /*}*/
            /*}*/
        }

    }
</style>
<template>
        <div class="box-value_picker_time" :style="{'height': heightBox + 'px',width:width}">
            <el-popover
                    placement="bottom"
                    width="150"
                    popper-class="hour-minute-popover"
                    v-model="isHourMinuteMod"
                    @show="picker.isFocus = true"
                    @hide="picker.isFocus = false"
                    trigger="click">
                <div class="box-value_before" slot="reference"></div>
                <el-row class="hour-minute-popover_title">
                    <el-col :span="12">
                        时
                    </el-col>
                    <el-col :span="12">
                        分
                    </el-col>
                </el-row>
                <el-row class="box-hour-minute">
                    <el-col :span="12">
                        <div style="height: 180px">
                            <el-scrollbar style="height: 100%">
                                <ul>
                                    <li v-for="(item, inHour) in dateHourSelect"
                                        :key="inHour"
                                        :class="{'box-li-select':item==timePicker.selectHour}"
                                        @click="hourSelect(item)">
                                        {{item}}
                                    </li>
                                </ul>
                            </el-scrollbar>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div style="height: 180px">
                            <el-scrollbar style="height: 100%">
                                <ul>
                                    <li v-for="(item,inMinute) in timeMinuteStep"
                                        :key="inMinute"
                                        :class="{'box-li-select':item==timePicker.selectMinute}"
                                        @click="minuteSelect(item)">
                                        {{item}}
                                    </li>
                                </ul>
                            </el-scrollbar>
                        </div>
                    </el-col>
                </el-row>
                <div class="hour-minute-popover_oper">
                    <el-button class="oper-close" @click="resetHourMinute">取消</el-button>
                </div>
            </el-popover>
            <div class="box-value_after" v-if="isTitle"
                 :class="{'box-value_after_empty':!timePicker}">
                <p :style="{'line-height': (heightBox - 4) + 'px'}">{{timePicker.selectHour + ':' + timePicker.selectMinute}}</p>
            </div>
            <div class="box-value_after" v-else
                 :class="{'box-value_after_empty':!timePicker}"
                 style="margin-top: 3px">
                <span v-text="title" v-if="timePicker"></span>
                <span v-text="placeholder" v-else></span>
                <p>{{timePicker.selectHour + ':' + timePicker.selectMinute}}</p>
            </div>
            <i class="box-value_icon"
               :class="{'value_icon_caret_focus':picker.isFocus,
               'el-icon-caret-bottom': !isIconLine,
               'el-icon-arrow-down': isIconLine}"
            :style="{'line-height': (heightBox - 4) + 'px'}"></i>
            <!--<i class="box-value_icon el-icon-circle-close"-->
               <!--:class="{'box-value_icon_close':!timePicker}"-->
               <!--@click="handleClear()"></i>-->
        </div>
</template>

<script type="text/ecmascript-6">
    export default {
        //定义模版数据
        data() {
            return {
                picker: {
                    isFocus: false
                },
                isHourMinuteMod: false,
                timePicker:{
                    selectHour: '',   // 小时
                    selectMinute: '',  // 分
                },
                selectTimeInfo: ''
            }
        },
        props: {
            title: {
                type: String,
                default: "title"
            },
            placeholder: {
                type: String,
                default: ""
            },
            defaultHour: {
                type: String,
                default: "09"
            },
            defaultMinute: {
                type: String,
                default: "00"
            },
            timeMinuteStep: {
                type: Array,
                default: function () {
                    return ['00', '10', '20', '30', '40', '50'];
                }
            },
            dateHourSelect: {
                type: Array,
                default: function () {
                    let dates = [];
                    for (let i = 0; i < 24; i++) {
                        let j = i;
                        if (i < 10)
                            j = '0' + i;
                        dates.push(j.toString())
                    }
                    return dates;
                },
            },
            isTitle:{
                type: Boolean,
                default: false
            },
            isIconLine:{
                type: Boolean,
                default: false
            },
            heightBox: {
                type: String,
                default: "40"
            },
            width:{
                type:String,
                default:'100%'
            },
            indexIdent: {
                type: Object,
                default: function () {
                    return {}
                }
            }

        },
        //计算属性
        computed: {},
        //主件被加载完成
        mounted: function () {
            this.timePicker.selectHour = this.defaultHour;
            this.timePicker.selectMinute = this.defaultMinute;
            if(this.selectTimeInfo) this.selectTimeInfo = this.defaultHour + ':' + this.defaultMinute;
            else this.selectTimeInfo = this.timePicker.selectHour + ':' + this.timePicker.selectMinute;
        },
        //定义事件方法
        methods: {

//            handleClear() {
//                this.timePicker.selectHour = "";
//                this.timePicker.selectMinute = "";
//                this.selectStep = "";
//                this.$emit("onUpdate", "");
//            },
            getTimeHourM() {
                return this.selectTimeInfo;
            },
            hourSelect(h) {
                this.timePicker.selectHour = h;
                this.selectTimeInfo = this.timePicker.selectHour + ':' + this.timePicker.selectMinute;
//                this.isHourMinuteMod=false;
                this.$emit("onUpdate", this.selectTimeInfo, this.indexIdent);
            },
            minuteSelect(m) {
                this.timePicker.selectMinute = m;
                if(this.timePicker.selectHour){
                    this.selectTimeInfo = this.timePicker.selectHour + ':' + this.timePicker.selectMinute;
                    this.isHourMinuteMod=false;
                    this.$emit("onUpdate", this.selectTimeInfo, this.indexIdent);
                }

            },
            resetHourMinute(){
                this.isHourMinuteMod=false
            },
//            subHourMinute(){
//                this.isHourMinuteMod=false
//            }

        },
        //监听模版变量
        watch: {
            defaultHour(n){
                this.timePicker.selectHour = n;
            },
            defaultMinute(n){
                this.timePicker.selectMinute = n;
            },
        }

    }
</script>
