<style lang="scss" scoped>
    .container {
        color: #333;
        text-align: center;
        font-size: 18px;
        height: 100%;
        padding-top: 40px;
    }
</style>
<template>

    <div class="container">
        <p v-if="content">
            {{content}}
        </p>
        <slot></slot>
    </div>

</template>

<script type="text/ecmascript-6">
    export default {
        props: {
            content: {
                type: String,
                default: '暂无数据'
            }
        }
    }
</script>