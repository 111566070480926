<style lang="scss" scoped>
    .dispatch-grab /deep/ {
        height: 100vh;
        position: relative;
        .el-form-item {
            margin-bottom: 0;
            border-bottom: 1px solid #f8f8f8;
            &:last-child, &:nth-last-child(2) {
                border-bottom: 0;
            }
        }
        .el-input__inner {
            height: 50px;
            line-height: 50px;
            font-size: 17px;
            border: 0;
        }
        .header {
            font-size: 12px;
            padding: 15px 12px;
            color: #666;
        }
        .driver-public {
            background: #FFFFFF;
            position: relative;
            p {
                font-size: 16px;
                line-height: 50px;
            }
            .el-form-item__content {
                display: flex;
                flex-direction: row;
                padding: 0 15px;
                .el-input__inner {
                    padding: 0;
                }

            }
        }
        .driver-price {
            p {
                min-width: 110px;
            }
            .price_input {
                flex: 1;
                font-size: 17px;
            }
        }
        .mod-confirm-footer {
            display: flex;
            position: absolute;
            width: 100%;
            bottom: 0;
            left: 0;
            .mint-button {
                flex: 1;
                border: 0;
                font-size: 17px;
                border-radius: 0;
                background: #fff;
                color: #fff;
                height: 44px;
                line-height: 44px;
                padding: 0;
                background: linear-gradient(90deg, rgba(80, 167, 242, 1) 0%, rgba(76, 219, 245, 1) 100%);

            }
        }
    }

</style>
<template>
    <div class="dispatch-grab">
        <p class="header">将分派至抢单列表，<span style="color: #319BF7">{{name}}</span>下的所有司机均可抢单，请输入司机到手价：</p>
        <el-form :model="grabForm" ref="grabForm">
            <el-form-item class="driver-public driver-price">
                <p>司导到手价：￥</p>
                <!--<el-input v-model="driverForm.name" placeholder="司导姓名" @change="getDriverLib"></el-input>-->
                <input class="price_input" placeholder="请输入价格" type="number"
                       v-model="grabForm.driverHandPrice">
            </el-form-item>
        </el-form>
        <div class="mod-confirm-footer">
            <mt-button @click="confirmBtn">确 定</mt-button>
        </div>
    </div>
</template>

<script type="text/ecmascript-6">
    import {Toast, Button} from 'mint-ui';


    export default {
        //定义模版数据
        data() {

            return {
                grabForm: {},
                confirmShow: false,
                orderId: this.$route.params.orderId,
                name: this.$route.params.name
            }
        },
        components: {
            MtButton: Button
        },
        //计算属性
        computed: {},
        activated() {

        },
        //主件被加载完成
        mounted: function () {


        },
        //定义事件方法
        methods: {
            async confirmBtn() {
                if (!this.grabForm.driverHandPrice)
                    return Toast('请填写司机到手价');
                let res = await this.http('/galaxyOrder/manualSendOrderGrab', {
                    id: this.orderId,
                    driverPrice: this.grabForm.driverHandPrice
                }, 'POST')
                if (res.success && res.data) {
                    this.$store.dispatch('changeOrderStatus', {
                        orderId: this.orderId,
                        orderStatus: 32
                    })
                    Toast('分派抢单成功');
                    this.$router.go(-1);
//                            this.getOrderList(this.$refs['searchForm'].searchForm, true);
                } else {
                    Toast(res.errors[0].message)
                }
            },
        },
        //监听模版变量
        watch: {}

    }
</script>
