<style lang="scss" scoped>
    .dispatch-grab /deep/ {
        height: 100vh;
        position: relative;
        .el-form-item {
            margin-bottom: 0;
            border-bottom: 1px solid #f8f8f8;
            &:last-child, &:nth-last-child(2) {
                border-bottom: 0;
            }
        }
        .el-input__inner {
            height: 50px;
            line-height: 50px;
            font-size: 17px;
            border: 0;
        }
        .header {
            font-size: 12px;
            padding: 15px 12px;
            color: #666;
        }
        .driver-public {
            background: #FFFFFF;
            position: relative;
            p {
                font-size: 16px;
                line-height: 50px;
            }
            .el-form-item__content {
                display: flex;
                flex-direction: row;
                padding: 0 15px;
                .el-input__inner {
                    padding: 0;
                }

            }
        }
        .driver-price {
            p {
                min-width: 110px;
            }
            .price_input {
                flex: 1;
                font-size: 17px;
            }
        }
        .mod-confirm-footer {
            display: flex;
            position: absolute;
            width: 100%;
            bottom: 0;
            left: 0;
            .mint-button {
                flex: 1;
                border: 0;
                font-size: 17px;
                border-radius: 0;
                background: #fff;
                color: #fff;
                height: 44px;
                line-height: 44px;
                padding: 0;
                background: linear-gradient(90deg, rgba(80, 167, 242, 1) 0%, rgba(76, 219, 245, 1) 100%);

            }
        }
    }

</style>
<template>
    <div class="dispatch-grab">
        <p class="header">将订单分配给：</p>
        <el-form :model="distForm" ref="distForm">
            <el-form-item class="driver-public driver-price">
                <p class="clear" style="width: 100%;position: relative" @click="handSupplier">
                    订单来源：
                    <span style="font-size: 14px;" :style="{color: supplierInfo.id?'#333':'#b1b1b1'}">{{supplierInfo.supplierName||'请分配供应商'}}</span>
                    <span style="color: #999;position: absolute;right: -5px;top: 0px;font-size: 12px"><i
                            class="icon icon-small-arrow-right"></i></span></p>
            </el-form-item>
            <el-form-item class="driver-public driver-price" style="margin-top: 10px">
                <p>结算金额：</p>
                <input class="price_input" placeholder="请输入价格" type="number"
                       v-model="distForm.settleAmount">
                <span style="line-height: 50px">元</span>
            </el-form-item>
        </el-form>
        <div class="mod-confirm-footer">
            <mt-button @click="confirmBtn">确 定</mt-button>
        </div>
        <!--供应商列表-->
        <mt-actionsheet
                :actions="orderSuppliers"
                v-model="supplierSheetVisible">
        </mt-actionsheet>
    </div>
</template>

<script type="text/ecmascript-6">
    import {Toast, Button, Actionsheet} from 'mint-ui';
    import orderServer from '@/urls/orderUrls'

    export default {
        //定义模版数据
        data() {

            return {
                distForm: {},
                orderId: this.$route.params.orderId,
                operatorId: this.$route.params.operatorId,
                orderStatus: this.$route.params.orderStatus,
                orderSuppliers: [],
                supplierSheetVisible: false,
                supplierInfo: {}
            }
        },
        components: {
            MtButton: Button,
            MtActionsheet: Actionsheet,
        },
        //计算属性
        computed: {},
        activated() {

        },
        //主件被加载完成
        mounted: function () {

        },
        //定义事件方法
        methods: {
            async getSupplier(){
                const ret = await orderServer.getSupplierInfoByOperatorId(
                    {operatorId: this.operatorId})
                if (ret.success && ret.data) {
                    this.orderSuppliers = ret.data
                    this.orderSuppliers.forEach((item) => {
                        item.name = item.supplierName;
                        item.method = this.supplierItemSelected
                    })
                    this.supplierSheetVisible = true;
                }
                else
                    Toast("抱歉，获取供应商列表失败")
            },
            handSupplier(){
                this.getSupplier();
            },
            //确定供应商
            async supplierItemSelected(e) {
                console.log(e, 23423432423423);
                this.supplierInfo = e;
            },
            async confirmBtn() {
                if(!this.supplierInfo.id)
                    return Toast('请选择供应商');
                if (!this.distForm.settleAmount)
                    return Toast('请填写结算金额');
                let form = {
                    "orderId": this.orderId,
                    "supplierId": this.supplierInfo.id,
                    "settleAmount": this.distForm.settleAmount,
                    "supplierNumber": this.supplierInfo.supplierId,  //供应商短编号
                    "supplierName": this.supplierInfo.supplierName, // 供应商名字
                }
                let res = null;
                if (this.orderStatus == 21)
                    res = await orderServer.addSupplier(form);
                if (this.orderStatus == 23)
                    res = await orderServer.reallocateSupplier(form);
                if (res.success) {
                    this.$store.dispatch('changeOrderStatus', {
                        orderId: this.orderId,
                        orderStatus: 22
                    })
                    Toast("供应商分配成功");
                    this.$router.go(-1);
                } else
                    Toast("供应商分配失败")
            },
        },
        //监听模版变量
        watch: {}

    }
</script>
