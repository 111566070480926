<style lang="scss" scoped>
    @import "style";

    .mt-popup-type_cus {
        left: 0;
        right: 0;
        z-index: 99999;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    .mod-screen_foot {
        display: flex;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        .screen-foot-cancel {
            position: relative;
            color: #319BF7;
            background: #fff;
        }
    }

    .box-btn {
        display: flex;
        justify-content: flex-end;
        button {
            margin-left: 5px;
        }
    }
</style>
<style lang="scss">
    .mint-toast {
        z-index: 99999;
    }

    .mint-popup {
        width: 100%;
    }

    .list-pop-bot {
        border-bottom: 1px solid #f8f8f8;
    }

    .institutions-list-pop, .product-pop-list {
        &.el-autocomplete-suggestion li {
            padding: 0 6px;
            word-wrap: break-word;
            word-break: normal;
            white-space: inherit;
            .list-pop-bot {
                position: relative;
                padding: 0 5px;

                &:before {
                    content: "";
                    display: block;
                    height: 1px;
                    background: #f8f8f8;
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0;
                }
            }
        }
    }
</style>
<template>
    <div class="order-manage">
        <ul class="order-manage_head">
            <li :class="{'manage-type_select':orderBarValue == '1' && orderTypeShow}" @click="handOrderType('1')">
                订单类型
                <i class="icon icon-triangle-down"></i>
            </li>
            <li :class="{'manage-type_select1':orderBarValue == '2'}" @click="handTravelDate('2')">
                出行时间
                <p class="head-date-icon">
                    <span class="triangle-up_icon">
                        <i class="icon icon-triangle-up"
                           :class="{'color-select':travelDateShow && orderBarValue == '2'}"></i>
                    </span>
                    <span class="triangle-down_icon">
                        <i class="icon icon-triangle-down"
                           :class="{'color-select':!travelDateShow && orderBarValue == '2'}"></i>
                    </span>
                </p>
            </li>
            <li :class="{'manage-type_select':orderBarValue == '3' && screenShow}" @click="handScreen('3')">
                筛选
                <i class="icon icon-screen"></i>
            </li>
        </ul>
        <div class="order-manage_sect sect-overflow"
             ref="scroll"
             v-infinite-scroll="loadMore"
             :infinite-scroll-disabled="page.hasMore"
             :infinite-scroll-immediate-check="false"
             infinite-scroll-distance="10">
            <div class="order-manage-box" v-for="list in listData" :key="list.orderId">
                <div class="box-title">
                    <p class="box-num">{{list.orderNo}}</p>
                    <p class="box-state">
                        <span :class="{'state-completed_c':list.orderStatus == '61', 'state-cancelled_c':(list.orderStatus == '72' || list.orderStatus == '2')}">{{list.orderStatus | stateDesc(orderStaList)}}</span>
                    </p>
                </div>
                <ul class="box-order-list" @click="goToNext('orderDetails',{orderId:list.orderId})">
                    <li>
                        <span class="span-icon"><i class="icon icon-vehicle-time"></i></span>
                        {{list.reserveTime | dateCus('yyyy-MM-dd hh:mm')}}
                    </li>
                    <li>
                        <span class="span-icon"><i class="icon icon-star-place"></i></span>
                        <p>{{list.productName}}</p>
                    </li>
                    <li>
                        <span class="span-icon"><i class="icon icon-end-place"></i></span>
                        <p>{{list.resourceName}}</p>
                    </li>
                    <li>
                        <span class="span-icon"><i class="icon icon-passenger-name"></i></span>
                        {{list.tripUserName}}  {{list.tripUserMobile}}
                    </li>
                    <li>
                        <span class="span-icon"><i class="icon icon-jiage"></i></span>
                        <span style="color: #FF3B30;font-weight: 700;">
                            {{list.orderAmount | currency}}
                        </span>
                    </li>
                </ul>
                <div class="box-btn">
                    <mt-button :outline="true" :inline="true" :primary="true"
                               @click="goToNext('orderNotes',{orderId:list.orderId,orderNo:list.orderNo})">客服备注
                    </mt-button>
                    <mt-button :outline="true" :inline="true" :primary="true"
                               @click="goToNext('orderLog',{orderId:list.orderId})">订单日志
                    </mt-button>
                    <mt-button :outline="true" :inline="true" :primary="true"
                               @click="goToNext('orderDetails',{orderId:list.orderId})">订单详情
                    </mt-button>
                    <mt-button :outline="true" :inline="true" :primary="true"
                               v-if="(list.orderStatus==31||list.orderStatus==41)&&userInfo.userType!=1 && list.sourceType!= 2 &&list.sourceType!= 7"
                               @click="cancelOrder(list)"
                    >取消订单
                    </mt-button>
                </div>
            </div>
            <div v-if="!orderList || orderList.length == 0" style="margin: 20px;text-align: center">
                暂无查询结果
            </div>
        </div>
        <refresh-top @onRefresh="refOrderManage('2')"></refresh-top>
        <mt-popup
                v-model="orderTypeShow"
                popup-transition="popup-fade"
                class="mt-popup-type_cus"
                :modal="false"
                position="bottom"
                :style="{top: headTop + 'px'}">
            <div class="order-type-list">
                <ul class="clear">
                    <li v-for="(list, index) in tabsStates" :key="index"
                        @click="orderStaBtn(list.value, list.key)">
                        <p class="order-type_state"
                           :class="{'order-type_select':orderScreen.value == list.value}">
                            {{list.name}}<span>{{list.num || 0}}</span>
                            <span class="type_state_completed"><i class="icon icon-completed"></i></span>
                        </p>
                    </li>
                </ul>
            </div>
        </mt-popup>
        <mt-popup
                v-model="screenShow"
                popup-transition="popup-fade"
                class="mt-popup-type_cus"
                :modal="false"
                position="bottom"
                :style="{top: headTop + 'px'}">

            <div class="screen-mod-list">
                <el-form :model="form" ref="form">
                    <et-actionsheet class="mod-screen_border"
                                    title="时间范围："
                                    :options="timeIntervals"
                                    v-model="form.queryDay">
                    </et-actionsheet>
                    <el-form-item class="mod-screen_border">
                        <div class="public_date" @click="handDateStart">
                            <div v-if="form.tripStartTime">
                                <p class="public_date_t">出行起始日期</p>
                                <p class="public_date_s">{{ form.tripStartTime | dateCus('yyyy-MM-dd') }}</p>
                            </div>
                            <p class="public_date_t_d" v-else>出行起始日期</p>
                        </div>
                        <span class="date-arrow-right"><i class="icon icon-small-arrow-right"></i></span>
                        <div class="public_date public_date_right" @click="handDateEnd">
                            <div v-if="form.tripEndTime">
                                <p class="public_date_t">出行截止日期</p>
                                <p class="public_date_s">{{ form.tripEndTime | dateCus('yyyy-MM-dd') }}</p>
                            </div>
                            <p class="public_date_t_d" v-else>出行截止日期</p>
                        </div>
                    </el-form-item>
                    <el-form-item class="mod-screen_border" label="产品编号：">
                        <input v-model="form.productNo" placeholder="请输入产品编号">
                    </el-form-item>
                    <el-form-item class="mod-screen_border" label="产品名称：">
                        <el-autocomplete
                                style="flex:1"
                                popper-class="product-pop-list"
                                v-model="form.productName"
                                :fetch-suggestions="getProductResName"
                                @select="handSelectProduct"
                                :trigger-on-focus="false"
                                placeholder="请输入产品名称搜素"
                                clearable>
                            <template slot-scope="{ item }">
                                <div class="list-pop-bot">
                                    <!--<div>-->
                                    <!--{{'[' + item.productNo + ']'}}-->
                                    <!--<span>{{item.productName}}</span>-->
                                    <!--</div>-->
                                    <div>
                                        <span>{{item.productName}}</span>
                                    </div>
                                </div>
                            </template>
                        </el-autocomplete>
                    </el-form-item>
                    <el-form-item class="mod-screen_border" label="资源名称：">
                        <el-autocomplete
                                style="flex:1"
                                popper-class="product-pop-list"
                                v-model="form.resourceName"
                                :fetch-suggestions="getResourceResName"
                                @select="handSelectResource"
                                :trigger-on-focus="false"
                                placeholder="请输入资源名称搜素"
                                clearable>
                            <template slot-scope="{ item }">
                                <div class="list-pop-bot">
                                    <!--<div>-->
                                    <!--{{'[' + item.productNo + ']'}}-->
                                    <!--<span>{{item.productName}}</span>-->
                                    <!--</div>-->
                                    <div>
                                        {{'[' + item.resourceNo + ']'}}
                                        <span>{{item.resourceName}}</span>
                                    </div>
                                </div>
                            </template>
                        </el-autocomplete>
                    </el-form-item>
                    <el-form-item class="mod-screen_border" label="出行人姓名：">
                        <input v-model="form.tripUserName" placeholder="姓名">
                    </el-form-item>
                    <el-form-item class="mod-screen_border" label="出行人手机：">
                        <input v-model="form.tripUserMobile" type="tel" maxlength="11" placeholder="手机">
                    </el-form-item>
                    <el-form-item>
                        <mt-button style="width: 80px" :outline="true" :inline="true" :primary="true"
                                   @click="handleReset">重置
                        </mt-button>
                    </el-form-item>
                </el-form>
            </div>
            <div class="mod-screen_foot">
                <mt-button class="screen-foot-cancel" @click="handCancel()">取消</mt-button>
                <mt-button class="screen-foot-sub" @click="handSubmit()">确定</mt-button>
            </div>
        </mt-popup>
        <mt-datetime-picker
                ref="dateStart"
                v-model="picker.startTime"
                type="date"
                :startDate="dateOption.startDate"
                :endDate="dateOption.endDate"
                @confirm="handPickerS">
        </mt-datetime-picker>
        <mt-datetime-picker
                ref="dateEnd"
                v-model="picker.endTime"
                type="date"
                :startDate="dateOption.startDate"
                :endDate="dateOption.endDate"
                @confirm="handPickerE">
        </mt-datetime-picker>
        <!--供应商列表-->
        <mt-actionsheet
                :actions="orderSuppliers"
                v-model="supplierSheetVisible">
        </mt-actionsheet>
        <!--订单来源-->
        <mt-popup position="bottom"
                  v-model="sourceTypeShow">
            <div style="height: 205px;width: 100%;">
                <mt-picker :slots="slots" value-key="name" @change="sourceTypeChange"></mt-picker>
            </div>
        </mt-popup>
    </div>
</template>

<script type="text/ecmascript-6">
    import dataInfo from "@/data/orderData/data";
    import refreshTop from '../../../components/refreshTop'
    import EtActionsheet from '@/components/et-actionsheet/index'
    import {Toast, Popup, InfiniteScroll, DatetimePicker, Actionsheet, Picker} from 'mint-ui';
    import {Button} from 'cube-ui'
    import Vue from 'vue';
    import CustomerServiceNotes from '../components/customer-service-notes/index'

    Vue.use(InfiniteScroll);

    export default {
        //定义模版数据
        data() {
            let userInfo = this.$store.state.loginUserInfo;
            let sourceTypeList = dataInfo.sourceTypeList;
            sourceTypeList.unshift({name: '全部', value: 99})
            return {
                timeIntervals: [
                    {
                        name: '近30天订单',
                        value: 30
                    },
                    {
                        name: '近60天订单',
                        value: 60
                    },
                    {
                        name: '近90天订单',
                        value: 90
                    },
                    {
                        name: '全部订单',
                        value: -1
                    }
                ],
                userInfo: userInfo,
                dateOption: {
                    startDate: new Date('2017/01/01'),
                    endDate: new Date(+new Date() + 7948800000),
                },
                headTop: 51,
                orderTypeShow: false,
                orderList: [],
                orderBarValue: '2',
                screenShow: false,
                travelDateShow: false,
                tabsStates: [
                    {
                        name: '全部',
                        value: []
                    },
                    {
                        name: '待确认订单',
                        value: ['21', '22', '23'],
                        key: 'orderStatusList'
                    },
                    {
                        name: '待出行',
                        value: [41],
                        key: 'orderStatusList'
                    },
                    {
                        name: '已完成',
                        value: [61],
                        key: 'orderStatusList'
                    },
                    {
                        name: '取消订单',
                        value: [1, 2, 3],
                        key: 'cancelStatusList'
                    },
                    {
                        name: '变更订单',
                        value: '1',
                        key: 'modifyStatus'
                    }
                ],
                orderStaList: [
                    {name: '全部', value: '', show: true, type: ''},
                    {name: '新订待分配', value: '21', num: 0, show: userInfo.userType != 1 ? true : false, type: 1},
                    {name: userInfo.userType != 1 ? '待供应商确认' : '新订单待确认', value: '22', num: 0, show: true, type: 1},
                    {name: '供应商拒绝', value: '23', num: 0, show: userInfo.userType != 1 ? true : false, type: 1},
                    {name: '待分配司导', value: '31', num: 0, show: true, type: 1},
                    {name: '待出行', value: '41', num: 0, show: true, type: 1},
                    {name: '进行中', value: '51', num: 0, show: true, type: 1},
                    {name: '已完成', value: '61', num: 0, show: true, type: 1},
                    {name: '取消中', value: '71', num: 0, show: false, type: 2},
                    {name: '已取消', value: '72', num: 0, show: false, type: 2},
                    {name: '用户申请取消', value: '1', num: 0, show: true, type: 2},
                    {name: userInfo.userType != 1 ? '待供应商确认取消' : '取消待处理', value: '3', num: 0, show: true, type: 2},
                    {name: '已取消', value: '2', num: 0, show: true, type: 2},
                    {name: userInfo.userType != 1 ? '待供应商确认变更' : '订单变更待处理', value: '1', show: true, type: 3}
                ],
                modifyStaCount: 0,
                orderScreen: {  // 订单状态
                    value: '',  // 订单类型
                    key: ''  // 状态类型
                },
                form: {
                    queryDay: 30,
                    tripStartTime: null,
                    tripEndTime: null
                },
                page: {
                    pageIndex: 0,
                    pageSize: 10,
                    hasMore: true,
                },
                scrollTop: 0,
                orderSuppliers: [],//订单所属供应上列表
                supplierSheetVisible: false,
                distributionSupplier: null,//当前待分配供应商的订单
                sourceTypeList: dataInfo.sourceTypeList,
                sourceTypeShow: false,
                slots: [
                    {
                        flex: 1,
                        values: sourceTypeList
                    }
                ],
                sourceType: {name: '全部', value: 99},
                serviceCityList: null,  //城市
                timer: null,
                selectCity: null,
                isCityShow: false,
                refreshDate: null,
                picker: {
                    startTime: new Date(),
                    endTime: new Date()
                }
            }
        },
        filters: {
            stateDesc(s, list) {
                let name;
                list.forEach(function (item) {
                    if (item.value == s)
                        name = item.name;
                })
                return name;
            }
        },
        components: {
            refreshTop,
            MtButton: Button,
            MtPopup: Popup,
            MtActionsheet: Actionsheet,
            MtDatetimePicker: DatetimePicker,
            MtPicker: Picker,
            EtActionsheet,
            CustomerServiceNotes
        },
        //计算属性
        computed: {
            orderStaListData() {
                return this.orderStaList.filter(({type}) => type !== 'trip')
            },
            listData() {
                return this.$store.state.orderList.tripList.filter(({orderStatus}) => orderStatus !== 32)
            },
            list: function () {
                return this.$store.state.orderList.tripList;
            }
        },
        mounted() {
            this.refOrderManage('2');
        },
        activated() {
            this.$store.dispatch("changeOrderType", 1);
            this.$refs["scroll"].scrollTop = this.scrollTop;
        },
        deactivated() {
            this.scrollTop = this.$refs["scroll"].scrollTop;
        },
        //定义事件方法
        methods: {
            handOrderType(value) {
                if (value == '1' && !this.orderTypeShow)
                    this.orderStatusNum()
                this.orderTypeShow = !this.orderTypeShow;
                this.orderBarValue = value;
                if (this.orderTypeShow) this.screenShow = false;
            },
            orderStaBtn(value, key) {
                this.orderScreen.value = value;
                this.orderScreen.key = key;
                this.handOrderType();
                this.page.pageIndex = 1;
                this.travelDateShow = false;
                this.orderBarValue = 2;
                this.orderManage()
            },
            handScreen(value) {
                this.screenShow = !this.screenShow;
                this.orderBarValue = value;
                if (this.screenShow) this.orderTypeShow = false;
                this.sourceType = {name: '全部', value: 99}
            },
            handTravelDate(value) {
                this.travelDateShow = !this.travelDateShow;
                this.orderBarValue = value;
                this.screenShow = false;
                this.orderTypeShow = false;
                this.page.pageIndex = 1;
                this.orderManage()
            },
            refOrderManage(value) {
                this.orderBarValue = value;
                this.form.queryDay = 30;
                this.screenShow = false;
                this.orderTypeShow = false;
                this.page.pageIndex = 1;
                this.orderScreen = {  // 订单状态
                    value: '',  // 订单类型
                    type: ''  // 状态类型
                };
                this.orderManage()
            },
            handSubmit() {
                let d = new Date(this.form.tripEndTime).getTime() - new Date(this.form.tripStartTime).getTime();
                if (d < 0) {
                    return Toast('出行截止日期需大于出行起始日期');
                }
                this.page.pageIndex = 1;
                this.orderManage(this.form.tripStartTime, this.form.tripEndTime);
            },
            handCancel() {
                this.form = {
                    tripStartTime: this.form.tripStartTime,
                    tripEndTime: this.form.tripEndTime,
                };
                this.handScreen()
            },
            handDateStart() {
                this.picker.startTime = this.form.tripStartTime || new Date();
                this.$refs.dateStart.open();
            },
            handPickerS(date) {
                this.form.tripStartTime = date
                if (!this.form.tripEndTime || this.form.tripEndTime < this.form.tripStartTime)
                    this.form.tripEndTime = date
            },
            handDateEnd() {
                this.picker.endTime = this.form.tripEndTime || new Date();
                this.$refs.dateEnd.open();
            },
            handPickerE(date) {
                this.form.tripEndTime = date
            },
            loadMore() {
                if (!this.page.hasMore)
                    return;
                this.page.pageIndex++;
                this.orderManage()
            },
            onInputQuery() {
                clearTimeout(this.timer);
                this.timer = setTimeout(() => {
                    this.serviceCityList = null;
                    this.selectCity = null;
                    if (this.form.city && this.form.city.length > 0) {
                        this.galaxyCityList(this.form.city);
                    }
                }, 100);
            },
            createFilter(value) {
                return (restaurant) => {
                    return (restaurant.city.indexOf(value) === 0);
                };
            },
            //模糊查询
            async galaxyCityList(city) {  // 获取服务城市
                let form = {
                    grade: 2,
                    pageSize: 99999
                };
                let res = await this.http('/galaxyCity/list', form, 'POST');
                if (res.success) {
                    let cityList = res.data.data;
                    this.serviceCityList = city ? cityList.filter(this.createFilter(city)) : cityList;
                    if (this.serviceCityList) this.isCityShow = true
                } else {
                    Toast(res.errors[0].message)
                }
            },
            handleSelectCity(item) {
                this.selectCity = item;
                this.form.city = item.city;
                this.isCityShow = false
            },
            async operatorConfirm(list) {//供应商确认订单
                let form = {
                    orderId: list.orderId
                }
                let res = await this.http('/galaxyOrder/supplierConfirm', form, "POST", false);
                console.log(res);
                if (res.success) {
                    Toast('订单确认成功');
                    this.$store.dispatch('changeOrderStatus', {
                        orderId: list.orderId,
                        orderStatus: 31
                    })
                } else {
                    Toast(res.errors[0].message)
                }
            },
            async orderManage(startTime, endTime) {
                this.loadingShow();
                let form = {
                    ...this.form
                };
                if (form.queryDay === -1)
                    delete form.queryDay;
                form.pageIndex = this.page.pageIndex;
                form.pageSize = this.page.pageSize;
                form.sortField = "reserve_time";
                form.sortOrder = this.travelDateShow ? "asc" : "desc";  //  "desc"降序  "asc"升序
                if (this.selectCity)
                    form.productStartCityId = this.selectCity.shortCode;
                if (startTime)
                    form.tripStartTime = +new Date(startTime);
                if (endTime) {
                    let tripEndTime = new Date(endTime).getFullYear() + '/' + (new Date(endTime).getMonth() + 1) + '/' + new Date(endTime).getDate() + ' 23:59:59';
                    form.tripEndTime = +new Date(tripEndTime);
                }
                if (this.orderScreen.value)
                    form[this.orderScreen.key] = this.orderScreen.value
                if (this.page.pageIndex == 1) this.orderList = [];

                this.page.hasMore = false;
                let res = await this.http('/galaxyOrder/BackendListTripOrder', form, 'POST');
                if (res.success && res.data) {
                    res.data.data.forEach(item => {
                        if (item.departure) {
                            if (item.departure.indexOf('address') != -1) {
                                const departure = JSON.parse(item.departure);
                                // item.departure = departure.city + ' ' + (departure.title || departure.value);
                                item.departure = departure.title || departure.value;
                            }
                        } else
                            item.departure = '-';
                        if (item.destination) {
                            if (item.destination.indexOf('address') != -1) {
                                const destination = JSON.parse(item.destination);
                                // item.destination = destination.city + ' ' + (destination.title || destination.value);
                                item.destination = destination.title || destination.value;
                            }
                        } else
                            item.destination = '-';
                    })

                    this.orderList = this.orderList.concat(res.data.data);
                    if (this.orderBarValue == 3) {
                        this.screenShow = false;
                        this.travelDateShow = false;
                        this.orderBarValue = 2;

                    }
                    if (this.orderList.length != res.data.total)
                        this.page.hasMore = true
                    this.$store.dispatch('setOrderList', this.orderList);
                }
                this.loadingHide();
            },
            async orderStatusNum() {
                let res = await this.http('/galaxyOrder/GetOrderStatusCountNotUseCar', {}, 'POST');
                if (res.success && res.data) {
                    this.tabsStates.forEach((status, i) => {
                        if (i === this.tabsStates.length - 1)
                            return;
                        status.num = 0;
                        if (res.data.cancelStatus && res.data.cancelStatus.length > 0 && status.key === "cancelStatusList") {
                            res.data.cancelStatus.forEach(({cancelStatus, count}) => {
                                if (status.value.join(',').indexOf(cancelStatus) !== -1) status.num += count;
                            })
                        }
                        if (res.data.orderStatus && res.data.orderStatus.length > 0) {
                            res.data.orderStatus.forEach(({orderStatus, count}) => {
                                if (status.value.join(',').indexOf(orderStatus) !== -1) status.num += count;
                            })
                        }
                    })
                    this.tabsStates[5].num = res.data.modifyStatusCount
                    this.tabsStates = JSON.parse(JSON.stringify(this.tabsStates))
                }

            },
            goToNext(name, params) {
                this.$router.push({
                    name,
                    params
                });
            },
            handChannel() {
                this.sourceTypeShow = true;
            },
            sourceTypeChange(picker, values) {
                this.sourceType = values[0];
                this.form.sourceType = values[0].value === 99 ? null : values[0].value;
                this.sourceTypeShow = false;
            },
            cancelOrder(item) {//取消订单

                this.$router.push({
                    name: 'cancelOrder',
                    params: {
                        orderId: item.orderId,
                        refundAmount: item.refundAmount || 0,
                        paymentAmount: item.paymentAmount,
                        actualSettleAmount: item.actualSettleAmount
                    }
                });
            },
            //获取资源
            createResourceFilter(itemStr) {
                return (restaurant) => {
                    return (restaurant.resourceName.indexOf(itemStr) >= 0);
                };
            },
            async getResourceResName(resourceName, cb) {
                if (resourceName && resourceName.length > 1) {
                    let form = {
                        resourceName
                    }
                    let res = await this.http('/galaxyResource/doProductResourceByResourceName', form, 'POST');
                    if (res.success && res.data) {
                        let resourceList = res.data;
                        let resourceListRes = resourceName ? resourceList.filter(this.createResourceFilter(resourceName)) : resourceList;
                        cb(resourceListRes);
                    } else {
                        this.messageError('系统出错，请稍后!');
                    }
                }
            },
            handSelectResource({resourceName}) {
                this.form.resourceName = resourceName
            },
            //获取产品
            createProductFilter(itemStr) {
                return (restaurant) => {
                    return (restaurant.productName.indexOf(itemStr) >= 0);
                };
            },
            async getProductResName(productName, cb) {
                if (productName && productName.length > 1) {
                    let form = {
                        productName,
                        productTypes: ['3']
                    }
                    let res = await this.http('/galaxyProduct/selectProductByName', form, 'POST');
                    if (res.success && res.data) {
                        let productList = res.data;
                        let productListRes = productName ? productList.filter(this.createProductFilter(productName)) : productList;
                        cb(productListRes);
                    } else {
                        this.messageError('系统出错，请稍后!');
                    }
                }
            },
            handSelectProduct({productName}) {
                this.form.productName = productName
            },
            //重置过滤表单
            handleReset() {
                this.form = {
                    queryDay: 30,
                    tripStartTime: null,
                    tripEndTime: null
                }
            }
        }
    }
</script>
