<style lang="scss" scoped>
</style>
<template>

    <div>
        <mt-cell v-for="(vm,index) in list"
                 :title="vm.operateName"
                 :value="vm.operateTime | dateCus"
                 :label="vm.operateContent">
        </mt-cell>
        <et-result v-if="list.length===0" content="暂无日志信息"></et-result>
    </div>

</template>

<script type="text/ecmascript-6">
    import {Cell} from 'mint-ui';
    import orderUrls from '../../../../urls/orderUrls'
    import Result from '../../../components/et-result/'

    export default {
        //定义模版数据
        data() {
            return {
                list: []
            }
        },
        components: {
            MtCell: Cell,
            EtResult: Result
        },
        //主件被加载完成
        activated() {
            this.getData();
        },
        //定义事件方法
        methods: {
            async getData() {
                this.loadingShow();
                const ret = await orderUrls.getOrderAuditLogByOrderId(this.$route.params.orderId)
                this.loadingHide();
                if (ret.success && ret.data) {
                    this.list = ret.data;
                }
                else this.toastShow(ret.errors[0].message)
            }
        },
        //监听模版变量
        watch: {}

    }
</script>