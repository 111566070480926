/**
 * 给定一个数组 和一个值查找数组的某项
 */
export const findItem = (arr, value, labelKey = 'label', valueKey = 'value') =>
    (arr.find((item) => item[valueKey] === value) || {})[labelKey]
/**
 * 组合form 如果为空则不加入form
 * 部分接口传空字符串和null会查不到数据问题
 */
export const combinationForm = (data) => {
    let form = {};
    for (let key of Object.keys(data)) {
        if (data[key] || data[key] === 0)
            form[key] = data[key]
    }
    return form;
}
