<style lang="scss" scoped>
    .list {
        padding: 0 12px;
        background-color: #fff;
        li {
            padding: 10px 0;
            border-bottom: 1px solid #f8f8f8;
        }
    }
    .head-tit {
        padding: 5px 10px;
        background-color: #fff;
        font-size: 18px;
        color: #333;
    }
    .list-tit {
        color: rgb(144, 147, 153);
    }
</style>
<template>
    <div>
        <cube-form class="form"
                   ref="form"
                   :options="{layout:'classic'}"
                   :immediate-validate="false"
                   :model="model"
                   :schema="schema"></cube-form>
        <h2 class="head-tit">客服备注记录：</h2>
        <ul class="list">
            <li v-for="vm in list" :key="vm.id">
                <p class="list-tit">{{vm.loginName}} {{vm.mobile}} {{vm.createTime | dateCus}}</p>
                <p>
                    {{vm.orderRemark}}
                </p>
            </li>
        </ul>
    </div>
</template>

<script type="text/ecmascript-6">
    import {Textarea, Button, Form, Scroll} from 'cube-ui'
    import Toast from '../../../../common/js/toast'
    import orderUrls from '../../../../urls/orderUrls'

    export default {
        //定义模版数据
        data() {
            return {
                list: [],
                model: {
                    orderRemark: ''
                },
                schema: {
                    groups: [
                        {
                            legend: '客服备注',
                            fields: [
                                {
                                    type: 'textarea',
                                    modelKey: 'orderRemark',
                                    props: {
                                        placeholder: '备注内容',
                                        maxlength: 100
                                    },
                                    label: '',
                                    rules: {
                                        required: true
                                    },
                                    messages: {
                                        required: '请输入内容'
                                    }
                                }
                            ]
                        },
                        {
                            fields: [
                                {
                                    type: 'button',
                                    label: '提交',
                                    events: {
                                        'click': () => {
                                            this.submitHandler();
                                        }
                                    }

                                }
                            ]
                        }
                    ]
                }
            }
        },
        components: {
            CubeForm: Form,
            CubeScroll: Scroll
        },
        //计算属性
        computed: {},
        //主件被加载完成
        mounted() {
            this.getData();
        },
        //定义事件方法
        methods: {
            submitHandler() {
                this.$refs.form.validate(async (valide) => {
                    if (valide) {
                        this.loadingShow()
                        const ret = await  orderUrls.addOrderRemark({
                            ...this.model,
                            orderId: this.$route.params.orderId,
                            orderNo: this.$route.params.orderNo
                        })
                        this.loadingHide();
                        if (ret.success && ret.data) {
                            this.toastShow('备注添加成功！')
                            this.getData();
                            this.model.orderRemark = '';
                        } else this.toastShow('备注失败!')
                    }
                })

            },
            //获取备注列表
            async getData() {
                this.loadingShow();
                const ret = await orderUrls.OrderRemarkList({
                    orderId: this.$route.params.orderId
                })
                if (ret.success && ret.data) {
                    this.list = ret.data.sort((a, b) => a.createTime - b.createTime > 0 ? -1 : 1);
                }
                this.loadingHide();
            }
        },
        //监听模版变量
        watch: {}

    }
</script>
