const url = '//webapi.amap.com/maps?v=1.4.13&key=0bb2ba3ff63722595ab21b36357841cc';
const mapUiUrl = '//webapi.amap.com/ui/1.0/main.js?v=1.0.11';
//加载地图
let loadScrip = (url) => {
    return new Promise(function (resolve) {
        const script = document.createElement('script');
        script.charset = 'utf-8';
        script.src = url;
        script.onload = function () {
            resolve(true);
        }
        document.head.appendChild(script);
    })

}
//gaode地图加载
export const MapPoint = async function () {
    if (window.AMap)
        return window.AMap;
    else {
        await loadScrip(url);
        loadScrip(mapUiUrl);
        return window.AMap;
    }

};

//浏览器定位
export const getWebLocation = function (_this) {
    return new Promise(function (resolve, reject) {
        MapPoint().then(function (AMap) {
            if (!AMap)
                return reject('高德jssdk出错');
            AMap.plugin('AMap.Geolocation', function () {
                let geolocation = new AMap.Geolocation({
                    // 是否使用高精度定位，默认：true
                    enableHighAccuracy: true,
                    // 设置定位超时时间，默认：无穷大
                    // timeout: 10000,
                    // 定位按钮的停靠位置的偏移量，默认：Pixel(10, 20)
                    // buttonOffset: new AMap.Pixel(10, 20),
                    //  定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
                    // zoomToAccuracy: true,
                    //  定位按钮的排放位置,  RB表示右下
                    // buttonPosition: 'RB'
                })

                geolocation.getCurrentPosition()
                AMap.event.addListener(geolocation, 'complete', onComplete)
                AMap.event.addListener(geolocation, 'error', onError)

                function onComplete(data) {
                    // data是具体的定位信息
                    console.log('successs', data);
                    let cityInfo = {
                        longitude: data.position.lng,
                        latitude: data.position.lat,
                        cityName: data.addressComponent.city || data.addressComponent.province
                    }
                    console.log(cityInfo);
                    resolve(cityInfo)

                    _this.$store.dispatch("CHANGE_LOCATION", cityInfo);
                }

                function onError(data) {
                    // 定位出错
                    console.log('errrrrrr', data);
                }
            })
        })
    })
}


//关键字检索
export const localSearch = function (searchKeyword, location) {
    return new Promise((resolve, reject) => {
        MapPoint().then(function (AMap) {
            if (!AMap)
                return reject('高德jssdk出错');
            AMap.plugin('AMap.Geolocation', function () {
                AMap.plugin('AMap.Autocomplete', function () {
                    // 实例化Autocomplete
                    var autoOptions = {
                        //city 限定城市，默认全国
                        city: location || '全国',
                        citylimit: true
                    }
                    var autoComplete = new AMap.Autocomplete(autoOptions);
                    autoComplete.search(searchKeyword, function (status, result) {
                        // 搜索成功时，result即是对应的匹配数据
                        console.log(status, result);
                        if (status == "complete") {
                            let localList = [];
                            if (result && result.tips && result.tips.length > 0) {
                                result.tips.forEach(item => {
                                    let sub1 = item.district.indexOf('省') + 1;
                                    if (sub1 === 0)
                                        sub1 = item.district.indexOf('区') + 1;
                                    let sub2 = item.district.indexOf('市') + 1;
                                    if (sub2 < sub1)
                                        sub1 = 0;
                                    if (item.location && item.location.lng) {
                                        localList.push({
                                            value: item.name,
                                            title: item.name,
                                            address: item.district + (item.address && item.address.length != 0 ? item.address : ''),
                                            city: item.district.substring(sub1, sub2),//item.city&&item.city.length!=0?item.city: _this.region,
                                            location: item.location.lng + ',' + item.location.lat,
                                        });
                                    }

                                })
                            }
                            resolve(localList);
                        }
                    })
                })
            })
        })
    })
}

export const getLocation = async function (_this) {
    return new Promise(function (resolve, reject) {
        MapPoint().then(function (AMap) {
            if (!AMap)
                return reject('高德jssdk出错');
            AMap.plugin('AMap.CitySearch', function () {
                let citySearch = new AMap.CitySearch()
                citySearch.getLocalCity(function (status, result) {
                    console.log(status, result, '123123');
                    if (status === 'complete' && result.info === 'OK') {
                        // 查询成功，result即为当前所在城市信息
                        console.log("用户当前位置:", result);
                        _this.$store.dispatch("CHANGE_LOCATION", result);
                        resolve(result);
                    }
                    else
                        reject('定位失败' + result)
                })
            })
        })
    })
}
