/**
 * 省市接口
 * grade 等级：1省2市3区县
 */
export const getCityData = async (_this, grade, parentCode) => {
    grade = Number(grade);
    let url = '/galaxyCity/list';
    const cityData = _this.sessionStorage.getObject('cityList') || null;
    if (cityData && grade === 2 && !parentCode) return cityData;
    const ret = await _this.http(url, {grade: grade, pageSize: 4000, parentCode: parentCode}, 'POST');
    if (ret.success && ret.data && ret.data.data) {
        if (grade === 2 && !parentCode) _this.sessionStorage.setObject('cityList', ret.data.data);
        return ret.data.data;
    }
    else
        return false;
}
