<style lang="scss" scoped>

    .city-select {
        width: 100%;
    }

    .select-city-pub.city-pub_border /deep/ {
        .el-select .el-input.is-focus .el-input__inner {
            border: 0;
        }
        .el-input__inner {
            border: 0;
            padding-right: 15px;
        }
        .el-input__suffix {
            display: none;
            opacity: 0;
        }
    }

</style>
<template>

    <div class="select-city-pub" :class="{'city-pub_border':isBorder}">
        <el-select placeholder="请选择城市"
                   class="city-select"
                   filterable
                   clearable
                   v-model="cityName"
                   @change="selectCity"
                   @focus="onFocus"
                   :loading="loading"
                   @visible-change="viChangeCity"
        >
            <el-option
                    v-for="item in serverCityList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.name">
            </el-option>
        </el-select>
    </div>

</template>

<script type="text/ecmascript-6">
    import {getCityData} from '@/urls/index';

    export default {
        //定义模版数据
        data() {
            return {
                cityName: this.defaultCity,
                serverCityList: [],
                isFocus: '',
                loading: true
            }
        },
        props: {
            defaultCity: {
                type: String,
            },
            defaultIndex: {
                type: Number,
                default: null
            },
            defaultIsFocus: {
                type: Boolean,
                default: false
            },
            isBorder: {
                type: Boolean,
                default: true
            },
        },
        //计算属性
        computed: {},
        //主件被加载完成
        mounted: function () {
            this.isFocus = this.defaultIsFocus;
//            if (!this.cityName) this.cityName = this.$store.state.location.cityName;
        },
        //定义事件方法
        methods: {
            async getServerCity() {
                try {
                    this.loading = true;
                    let res = await getCityData(this, '2');
                    if (res) {
                        this.serverCityList = res;
                    } else
                        this.serverCityList = [];
                    this.loading = false;

                } catch (e) {
                    console.log(e);
                }

            },
            selectCity(val) {
                this.$emit('update:getCity', val);
            },
            getCity() {
                return this.cityName;
            },
            setDefault(n) {
                if (this.serverCityList.length > n) {
                    this.cityName = this.serverCityList[n].name;
                    this.selectCity(this.cityName)
                }
            },
            viChangeCity(b) {
                this.isFocus = b;
                this.$emit("onUpdate:isFocus", this.isFocus);
            },
            onFocus() {
                if (this.serverCityList.length === 0)
                    this.getServerCity();
            }
        },
        //监听模版变量
        watch: {
            defaultCity(n) {
                this.cityName = n;
            },
            defaultIndex(n) {
                this.setDefault(n);
            },
            isFocus(n) {
                this.isFocus = n;
            }
        }

    }
</script>
