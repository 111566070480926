/**
*客服备注
*/
<style lang="scss" scoped>

</style>
<template>
    <div>
        <cube-button :outline="true" :inline="true" :primary="true">客服备注</cube-button>
    </div>
</template>

<script type="text/ecmascript-6">
    import {Button} from 'cube-ui'

    export default {
        //定义模版数据
        data() {
            return {}
        },
        components: {
            CubeButton: Button
        },
        //计算属性
        computed: {},
        //主件被加载完成
        mounted() {

        },
        //定义事件方法
        methods: {},
        //监听模版变量
        watch: {}

    }
</script>