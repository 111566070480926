<style lang="scss" scoped>
    .brand-model {
        height: 100vh;
        position: relative;
        .brand-model-title {
            height: 50px;
            text-align: center;
            line-height: 50px;
            width: 100%;
            font-size: 16px;
            .title-icon {
                float: left;
                i {
                    padding-left: 12px;
                    font-weight: 700;
                    font-size: 20px;
                }
            }
        }
        .english-label {
            height: 30px;
            line-height: 30px;
            padding-left: 12px;
            background-color: #f7f7f7;
            color: #b1b1b1;
            font-size: 16px;
        }
        .english-index {
            position: fixed;
            right: 12px;
            top: 45px;
            color: #0452DD;
            font-size: 12px;
        }
        .popup-content {
            height: 100%;
            width: 50%;
            min-width: 230px;
            float: right;
            background-color: #fff;
        }
    }

</style>
<style lang="scss">
    .brand-model {
        .mint-cell {
            z-index: 2017
        }
        .mint-popup-right {
            z-index: 2050 !important;
        }
    }
</style>
<template>
    <div class="brand-model clear">
        <div v-if="isTitle" class="brand-model-title">
            <div class="title-icon" @click="close"><i class="icon icon-left-arrow"></i></div>
            <span>选择车辆品牌</span>
        </div>
        <div v-for="(car, index) in brandModelListData" :key="index" :id="car.english">
            <p class="english-label">{{car.english}}</p>
            <mt-cell v-for="(vm,i) in car.list" :key="i" :title="vm.label"
                     @click.native="brandSelect(vm)"
                     :style="{'z-index': popupVisible&&brandModel.label===vm.label?2012: 0}">
            </mt-cell>
        </div>
        <ul class="english-index" :style="{top: isTitle?'95px':'45px'}">
            <li v-for="(eng,index) in englishList" :key="index"><a :href="`#${eng}`">{{eng}}</a></li>
        </ul>
        <mt-popup v-model="popupVisible" position="right" style="height: 100%;z-index:2030">
            <div class="popup-content">
                <mt-cell v-for="(vm,i) in brandModel.children" :key="i" :title="vm.label"
                         @click.native="carSelect(vm)"></mt-cell>
            </div>
        </mt-popup>
    </div>
</template>

<script type="text/ecmascript-6">
    import {Popup, Cell} from 'mint-ui';
    import {uniqueArray} from '@/common/js/Utils';


    export default {
        //定义模版数据
        data() {
            let brandModelList = [];
            let englishList = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
            englishList.split('')
                .forEach(function (data) {
                    brandModelList.push({
                        english: data,
                        list: []
                    });
                });
            return {
                brandModelList,
                englishList,
                popupVisible: false,
                brandModel: {}
            }
        },
        props: {
            isTitle: {
                type: Boolean,
                default: false
            }
        },
        components: {
            MtPopup: Popup,
            MtCell: Cell
        },
        //计算属性
        computed: {
            brandModelListData() {
                return this.brandModelList.filter(({list}) => list && list.length > 0)
            }
        },
        activated() {

        },
        //主件被加载完成
        mounted: function () {
            this.getBrandList();

        },
        //定义事件方法
        methods: {
            async getBrandList() {
                const list = this.sessionStorage.getObject("brandModel") || [];
                if (list && list.length > 0) return this.brandModelList = list;
                let res = await this.http('/galaxyStationApi/doCarBrandModelList', {}, 'POST');
                if (res.success) {
                    let brandModelLsit = uniqueArray(res.data, 'carBrand');
                    let carList = [];
                    brandModelLsit.forEach(item => {
                        let list = {
                            label: item.carBrand,
                            english: item.carBrandEnglish,
                            children: []
                        }
                        res.data.forEach(item1 => {
                            if (item.carBrand == item1.carBrand) {
                                list.children.push(item1);
                            }

                        })
                        carList.push(list);
                    })

                    this.brandModelList.forEach(item => {
                        carList.forEach(car => {
                            if (car.english === item.english) {
                                item.list.push(car)
                            }
                        })
                    });
                    this.sessionStorage.setObject("brandModel", this.brandModelList)
                }
            },
            brandSelect(vm) {
                this.popupVisible = !this.popupVisible;
                this.brandModel = vm;
            },
            carSelect(vm) {
                this.popupVisible = false;
                let data = {
                    brand: this.brandModel.label,
                    model: vm.label,
                    carType: vm.carType
                }
                console.log(data, 23423423);
                this.$emit("selectCar", data)
            },
            close() {
                this.$emit("input", false)
            }
        },
        //监听模版变量
        watch: {}

    }
</script>
